import { createAction } from '../common/actionUtils';
import {
  SetCashierPaymentPayload,
  SetContactFieldPayload,
  SubmitOrderSuccessPayload,
  SetContactPayload,
  SetDeliveryAddressPayload,
  SetCurbsidePayload,
  SetDispatchTypePayload,
  SetPaymentPayload,
  SetPendingCashierPaymentPayload,
  SetTipPayload,
  SetDispatchPayload,
  SetDispatchTimePayload,
  SetContactlessDeliveryPayload,
  SetCashierTokenPayload,
  SetCashierPaymentDonePayload,
  SetCheckoutStepPayload,
  SubmitSmsCodePayload,
  SetSmsErrorPayload,
  SetSmsLoadingPayload,
  SetErrorTypePayload,
  SetContactIdPayload,
  SetMembersAPiContactPayload,
  SetMemberContactLoadingPayload,
  SetSelectedAddressIdPayload,
  SetCurbsideOutfitInfoPayload,
  SetDineInTablePayload,
  SetLoyaltyPointsToRedeemPayload,
  ResetLoyaltyPointsToDefaultValuePayload,
  SetDeliveryAddressCommentPayload,
  SetDeliveryAddressLine2Payload,
  SetDeliveryAddressLabelPayload,
  SetDeliveryProviderEstimatePayload,
  SetIsFetchingEstimateFromTPAPayload,
} from './checkout.actions.types';

export const setContact = createAction<SetContactPayload>('SET_CONTACT');
export const setMembersAPiContact = createAction<SetMembersAPiContactPayload>('SET_MEMBERS_API_CONTACT_PAYLOAD');
export const setContactId = createAction<SetContactIdPayload>('SET_CONTACT_ID');
export const setDeliveryAddressFromForm = createAction('SET_ADDRESS_FROM_FORM');
export const setDeliveryAddressAdditionalInfo = createAction('SET_DELIVERY_ADDRESS_ADDITIONAL_INFO');
export const setDeliveryAddressLine2 = createAction<SetDeliveryAddressLine2Payload>('SET_DELIVERY_ADDRESS_LINE_2');
export const setDeliveryAddressComment = createAction<SetDeliveryAddressCommentPayload>('SET_DELIVERY_ADDRESS_COMMENT');
export const setDeliveryAddressLabel = createAction<SetDeliveryAddressLabelPayload>('SET_DELIVERY_ADDRESS_Label');
export const setContactField = createAction<SetContactFieldPayload>('SET_CONTACT_FIELD');
export const submitOrder = createAction('SUBMIT_ORDER');
export const submitSmsCode = createAction<SubmitSmsCodePayload>('SUBMIT_SMS_CODE');
export const abortSmsVerification = createAction('ABORT_SMS_VERIFICATION');
export const submitOrderSuccess = createAction<SubmitOrderSuccessPayload>('SUBMIT_ORDER_SUCCESS');
export const setDispatch = createAction<SetDispatchPayload>('SET_DISPATCH');
export const setDispatchType = createAction<SetDispatchTypePayload>('SET_DELIVERY_METHOD');
export const setDispatchTime = createAction<SetDispatchTimePayload>('SET_DISPATCH_TIME');
export const setDeliveryAddress = createAction<SetDeliveryAddressPayload>('SET_DELIVERY_ADDRESS');
export const setDineInTable = createAction<SetDineInTablePayload>('SET_DINE_IN_TABLE');
export const setCurbside = createAction<SetCurbsidePayload>('SET_CURBSIDE');
export const setCurbsideOutfitInfo = createAction<SetCurbsideOutfitInfoPayload>('SET_CURBSIDE_OUTFIT_INFO');
export const setPayment = createAction<SetPaymentPayload>('SET_PAYMENT');
export const setCashierPayment = createAction<SetCashierPaymentPayload>('SET_CASHIER_PAYMENT');
export const setPendingCashierPayment = createAction<SetPendingCashierPaymentPayload>('SET_PENDING_CASHIER_PAYMENT');
export const setTip = createAction<SetTipPayload>('SET_TIP');
export const setContactlessDelivery = createAction<SetContactlessDeliveryPayload>('SET_CONTACTLESS_DELIVERY');
export const setCashierToken = createAction<SetCashierTokenPayload>('SET_CASHIER_TOKEN');
export const setCashierPaymentDone = createAction<SetCashierPaymentDonePayload>('SET_CASHIER_PAYMENT_DONE');
export const clearOrderResponse = createAction('CLEAR_ORDER_RESPONSE');
export const setCheckoutStep = createAction<SetCheckoutStepPayload>('SET_CHECKOUT_STEP');
export const initCheckout = createAction('INIT_CHECKOUT');
export const setSmsError = createAction<SetSmsErrorPayload>('SET_SMS_ERROR');
export const setSmsLoading = createAction<SetSmsLoadingPayload>('SET_SMS_LOADING');
export const setMemberContactLoading = createAction<SetMemberContactLoadingPayload>('SET_MEMBER_CONTACT_LOADING');
export const setErrorType = createAction<SetErrorTypePayload>('SET_ERROR_TYPE');
export const loginMember = createAction('LOGIN_MEMBER');
export const logoutMember = createAction('LOGOUT_MEMBER');
export const setSelectedAddressId = createAction<SetSelectedAddressIdPayload>('SET_SELECTED_ADDRESS_ID');
export const clickGoToCheckout = createAction('GO_TO_CHECKOUT');
export const setLoyaltyPointsToRedeem = createAction<SetLoyaltyPointsToRedeemPayload>('SET_LOYALTY_POINTS_TO_REDEEM');
export const setDeliveryProviderEstimate = createAction<SetDeliveryProviderEstimatePayload>(
  'SET_DELIVERY_PROVIDER_ESTIMATE',
);
export const fetchTpaEstimate = createAction('FETCH_TPA_ESTIMATE');
export const setIsFetchingEstimateFromTPA = createAction<SetIsFetchingEstimateFromTPAPayload>(
  'SET_IS_FETCHING_ESTIMATE_FROM_TPA',
);
export const resetLoyaltyPointsToDefaultValue = createAction<ResetLoyaltyPointsToDefaultValuePayload>(
  'RESET_LOYALTY_POINTS_TO_DEFAULT_VALUE',
);
